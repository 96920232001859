<template>
  <p class="text-muted text-center"
     v-text="calculateOrdinalNumber(index, perPage, currentPage)"/>
</template>

<script>
  export default {
    props: {
      index: {
        type: Number,
        require: true,
      },
      currentPage: {
        type: Number,
        require: true,
      },
      perPage: {
        type: Number,
        require: true,
      },
    },
    methods: {
      calculateOrdinalNumber(index, perPage, currentPage) {
        return this.calculate_row_index(index, perPage, currentPage) + 1;
      },
    },
  };
</script>

<style scoped>

</style>
