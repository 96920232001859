<template>
  <vue-fragment v-if="isFilterVisible">
    <td v-for="filter in fields"
        v-show="show"
        :key="filter.key">
      <b-input v-if="!not_include_key.includes(filter.key)"
               v-model="filters[filter.key]"
               :placeholder="filter.label">
      </b-input>
    </td>
  </vue-fragment>
</template>
<script>
  export default {
    props: {
      fields: {
        type: Array,
        default: () => [],
      },
      value: undefined,
      selected_call: {
        type: String,
        default: 'None',
      },
      id: undefined,
      not_include_key: undefined,
    },
    data() {
      return {
        filters: {},
        filters_cached: {},
        proposals_filter: {},
        proposals: [],
        // change to prop to setup this value from parent
        show: true,
      };
    },
    computed: {
      isFilterVisible() {
        return this.fields.length !== 0
          && (Object.keys(this.filters_cached).includes(this.selected_call) || this.selected_call === 'None');
      },
    },
    methods: {
      create_filter(key) {
        const filter = {};
        key.forEach((item) => {
          filter[item.key] = '';
        });
        return filter;
      },
      get_filtered(items, fields) {
        return items.filter((item) => Object.keys(fields)
          .every((key) => String(this.lower(this.get_item(item, key))).includes(this.lower(fields[key]))));
      },
      get_item(item, key) {
        // check the deeper key level (e.g. value for key "general.discipline")

        // if the deeper key includes an Array (e.g key "samples[0].risk");
        if (key.includes('[0].')) {
          return key.split('.').reduce((o, k) => {
            if (o !== undefined) {
              if (k.includes('[0]')) {
                const cleanKey = k.slice(0, -3);
                if (this.hasProperty(o, cleanKey)) return o[cleanKey][0];
              }
              if (this.hasProperty(o, k)) {
                // if final value is an Array (e.g. samples[0].sample_safety)
                if (Array.isArray(o[k])) {
                  return o[k].join(', ');
                }
                return o[k];
              }
            }
            return undefined;
          }, item);
        }

        if (key.includes('.')) {
          return key.split('.').reduce((o, k) => {
            if (o !== undefined) {
              if (this.hasProperty(o, k)) return o[k];
            }
            return undefined;
          }, item);
        }
        return item[key];
      },
      lower(str) {
        return typeof str === 'string' || str instanceof String
          ? str.toLowerCase() : str;
      },
      setup(proposal) {
        // this is use as main collection to do filter logic
        this.proposals = proposal;
      },
      setupUserFilters(proposal) {
        // setup for users filtered by creation date and / or is_active
        this.proposals = proposal;
        const proposals = this.get_filtered(this.proposals, this.filters);
        this.update_proposals(proposals);
      },
      // update filter is use for all filter component on the b-tabs
      // TO-DO: create logic and update only one component that is visible
      update_proposals(proposals, allow_id) {
        if (this.id === allow_id || allow_id === undefined) {
          if (proposals !== undefined) {
            this.$set(this, 'proposals_filter', proposals);
          } else {
            const proposals = this.get_filtered(this.proposals, this.filters);
            this.update_proposals(proposals);
          }
        }
      },
      parse_filters() {
        if (!Object.keys(this.filters_cached).includes(this.selected_call)) {
          this.filters_cached[this.selected_call] = this.create_filter(this.fields);
        }
        this.filters = this.filters_cached[this.selected_call];
      },
    },
    watch: {
      selected_call: {
        handler() {
          this.parse_filters();
        },
        immediate: true,
      },
      proposals_filter() {
        this.$emit('input', this.proposals_filter);
      },
      filters: {
        handler() {
          const proposals = this.get_filtered(this.proposals, this.filters);
          this.update_proposals(proposals);
        },
        deep: true,
      },
    },
    created() {
      const parent = this.get_parent_by_id(this.id);
      parent.$on('setup_proposal', this.setup);
      parent.$on('setup-proposal-user-filters', this.setupUserFilters);
      // event to toggle visible of the filters input's
      parent.$on('toggle_show', () => {
        this.$set(this, 'show', !this.show);
      });
      parent.$on('refresh', this.update_proposals);
      this.$emit('component-created');
    },
  };
</script>
