<template>
  <div class="role_container">
    <b-card bg-variant="light"
            header="Roles"
            header-bg-variant="dark"
            header-text-variant="white">
      <b-button :pressed.sync="showFilter"
                class="mb-2">
        FILTER
      </b-button>
      <b-table :current-page="currentPage"
               :fields="fields"
               :items="users"
               :per-page="perPage"
               class="table-responsive"
               head-variant="light"
               hover
               responsive
               striped
               style="overflow: visible">
        <template v-slot:top-row="{fields}">
          <proposal-filter :id="id"
                           v-model="users"
                           :fields="fields"
                           :not_include_key="['actions', 'roles', 'ordinalNumber']">
          </proposal-filter>
        </template>

        <template v-slot:cell(ordinalNumber)="row">
          <base-column-ordinal-number v-bind="{index: row.index, perPage, currentPage}"/>
        </template>

        <template v-slot:cell(roles)="row">
          <multiselect v-model="row.item.temp_roles"
                       :closeOnSelect="false"
                       :multiple="true"
                       :options="options"
                       hideSelected
                       label="name"
                       placeholder="Click to select role"
                       track-by="role_id"
                       @input="touch_user(row.item)">
          </multiselect>
        </template>
      </b-table>
      <b-btn class="ma-2 right"
             variant="info"
             @click="submit_roles">SUBMIT
      </b-btn>
      <b-pagination v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="users.length"
                    size="md">
      </b-pagination>
    </b-card>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import proposalFilter from '@/components/proposal_filter';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';
  import { ordinalNumber } from '@/helpers/commonTableColumns';

  export default {
    name: 'role_dashboard',
    components: {
      BaseColumnOrdinalNumber,
      Multiselect,
      proposalFilter,
    },
    data() {
      return {

        showFilter: true,
        id: 1,

        roles: [],
        users: [],
        role_names: {},
        fields: [
          ordinalNumber,
          'full_name',
          'email',
          'roles',
        ],
        currentPage: 1,
        perPage: 20,
        options: [],
      };
    },
    methods: {
      load_roles() {
        this.axios.get('/roles')
          .then((resp) => {
            this.roles = resp.data;
            this.prepare_roles(resp.data);
          })
          .then(this.load_users());
      },
      load_users() {
        this.axios.get('/users/list_with_roles')
          .then((resp) => {
            this.users = resp.data;
            this.prepare_users();
            this.$emit('setup_proposal', this.users);
          });
      },
      prepare_users() {
        for (const i in this.users) {
          for (const j in this.users[i].roles) {
            if (this.users[i].roles[j].states.state) {
              this.users[i].full_name = `${this.users[i].first_name} ${
                this.users[i].last_name}`;
              this.users[i].temp_roles.push({
                name: this.role_names[this.users[i].roles[j].role_id],
                role_id: this.users[i].roles[j].role_id,
              });
            }
          }
        }
      },
      prepare_roles(roles) {
        const privilegedRoles = [
          'reviewer',
          'reviewManager',
          'microscope_cryo_reviewer',
          'microscope_cryo_reviewManager'];
        for (const i in roles) {
          this.role_names[roles[i]._id.$oid] = roles[i].name;
          if (!(roles[i].name.includes('DEPRECATED')) && !privilegedRoles.includes(roles[i].name)) {
            this.options.push(
              {
                name: roles[i].name,
                role_id: roles[i]._id.$oid,
              },
            );
          }
        }
      },
      touch_user(user) {
        user.dirty = true;
      },
      reset_dirty() {
        for (const index in this.users) {
          if (this.users[index].dirty) delete this.users[index].dirty;
        }
      },
      prepare_to_submit() {
        const users_to_submit = [];
        for (const index in this.users) {
          if (this.users[index].dirty) users_to_submit.push(this.users[index]);
        }
        return users_to_submit;
      },
      submit_roles() {
        const users_to_submit = this.prepare_to_submit();
        if (!users_to_submit.length > 0) {
          this.$notify({ type: 'warn', title: 'Change some roles to submit' });
          return;
        }
        this.axios.post('/users/roles', users_to_submit)
          .then(() => {
            this.$notify({ type: 'success', title: 'Submitted successfully' });
            this.reset_dirty();
          });
      },
    },
    watch: {
      showFilter() {
        this.$emit('toggle_show');
      },
    },
    created() {
      this.load_roles();
    },
  };
</script>

<style lang="scss"
       scoped>
  .multiselect--active {
    z-index: 999;
  }

  .card {
    margin: 4rem;
  }
</style>
